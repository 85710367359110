<template>
  <v-container>
    <v-progress-linear
      v-if="loading"
      indeterminate
      rounded
      color="primary"
    ></v-progress-linear>
    <div v-else>
    <div v-if="!$route.params.id">
      <v-row class="mt-4">
        <v-col cols="3" v-for="t in tenants" :key="t.id">
          <v-card @click="tenantClick(t.id)" style="height:250px" elevation="1">
            <v-toolbar color="white--text" elevation="0" :style="`background-color:${t.theme ? t.theme.primary : $store.state.ui.theme.primary}`">
              <v-toolbar-title>
                {{ t.name }}
              </v-toolbar-title>
              <v-spacer>
              </v-spacer>
              <v-icon large color="white" v-if="t.vis">mdi-toggle-switch-outline</v-icon>
              <v-icon large color="white" v-else>mdi-toggle-switch-off-outline</v-icon>
            </v-toolbar>
            <v-card-text>
              <v-card
                outlined
                height="100"
                class="d-flex justify-center align-center"
                style="border:none"
              >
              
              <img
                v-if="t.logo"
                class="logo"
                :src="'/uploads/tenants/' + t.logo"
              />
            </v-card>
            </v-card-text>
            <v-card-text class="d-flex justify-center"
              >ID: {{ t.id }} | {{ t.numUsers }} Benutzer | {{ numerus('? aktiver||aktive Kunde|n', t.numClients) }} </v-card-text
            >
            
          </v-card>
        </v-col>
      </v-row>

     <v-btn
      color="primary"
      elevation="4"
      fab
      large
      @click="$store.commit('alert', {title: 'Manuelles Hinzufügen von Mandanten nicht möglich', text: 'Durch die 1:1-Nutzung der TickTack-Kundentabelle lassen sich Mandanten hier nicht hinzufügen. Der Mandant muss in TickTack angelegt werden und dort mindestens einen aktiven Kunde besitzen. <br>Dann erscheint er hier als \'NEW TENANT\' und lässt sich entsprechend einrichten.'})"
      class="fab-new"
      ><v-icon>mdi-plus</v-icon></v-btn
    >
      
    </div>
    <router-view v-else :adminData="adminData"></router-view>
    </div>
  </v-container>
</template>

<script>
import { numerus } from "@/utils/strings"
export default {
  data() {
    return {
      loading: true,
      tenants: [],
    };
  },
  props: ["adminData"],
  methods: {
    numerus, 
    tenantClick(id) {
      this.$router.push({ name: "TenantForm", params: { id: id } });
    },
  },
  async created() {
    this.tenants = this.adminData.tenants;
    this.loading = false
  },
  watch: {
    adminData: function (val) {
      this.tenants = this.adminData.tenants;
    }
  }
};
</script>

<style  lang="scss">
img.logo {
  max-width: 200px;
  max-height: 100px;
}

.headline-link {
  display: inline-block;
  color: inherit !important;

  &:hover {
    text-decoration: underline;
  }
}
</style>